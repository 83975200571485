@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Outfit&family=Prompt:wght@200&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Outfit';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: grey; 
  border-radius: 10px;
}

.slider-wrapper {
  height: 220px;
  margin: 24px 0;
}

.slider-wrapper .left h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.019em;
  color: #FFFFFF;
  margin-bottom: 12px;
}

.slider-wrapper .left p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.011em;
  color: #EEEFF0;
}

.slider-wrapper .slick-list {
  height: 158px;
}

.slider-wrapper .slick-dots {
  display: flex !important;
  justify-content: flex-start;
  bottom: -59px;
}

.slider-wrapper .slick-dots li {
  margin: 0;
}

.slider-wrapper .slick-dots li.slick-active button:before {
  color: #FDA92F;
  opacity: 1;
}

.slider-wrapper .slick-dots li button:before {
  font-size: 12px;
}

.chrome-picker input {
  background: #FFFFFF
}

.animated-loading:after {
  content: '';
  animation: dots 2s linear infinite;
  -webkit-animation: dots 2s linear infinite;
}

.purple-checkbox:checked,
.purple-checkbox[checked="true"],
.purple-checkbox[aria-checked=true] {
  background-image: linear-gradient(-45deg, transparent 65%, hsl(239, 65%, 60%) 65.99%),
    linear-gradient(45deg, transparent 75%, hsl(239, 65%, 60%) 75.99%),
    linear-gradient(-45deg, hsl(239, 65%, 60%) 40%, transparent 40.99%),
    linear-gradient(45deg, hsl(239, 65%, 60%) 30%, hsl(var(--chkfg)) 30.99%, hsl(var(--chkfg)) 40%, transparent 40.99%),
    linear-gradient(-45deg, hsl(var(--chkfg)) 50%, hsl(239, 65%, 60%) 50.99%)
}

/* Toggle */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #3375F5;
}

@keyframes dots {
  0%, 20% {
    content: ' .';
  }
  40% {
    content: ' . .';
  }
  60% {
    content: ' . . .';
  }
  90%, 100% {
    content: '';
  }
}